import { VenueFullProfileImageSize } from '@/models/VenueFullProfileImageSize';
import { VENUE_PREFIX, URL_SLASH } from '@/constants/routeConstants';
import { queryStringConstants } from '@/constants/queryConstants';
import UrlBuilder, { type LocationObject } from '@/utils/urlBuilder';
import type { CsnSearchVenue } from '@/models/CsnSearchVenue';
import type { Locale } from '@/i18n';
import { SearchParams } from '@/models/SearchParams';
import { addExistingParamsToUrl, addResultsPageAnalyticsParamsToUrl, Params, prepareUrl } from '@/utils/urlHelper';
import { isProdEnv } from '@/utils/envHelper';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { ShoppingCartItem } from '@/models/ShoppingCartItem';
import type { ResultsPageAnalyticsParams } from '@/models/analyticsParams/ResultsPageAnalyticsParams';

const EMPTY_STRING = '';
const MICROSITE = 'MICROSITE';
const BRAND_PAGE = 'BRAND_PAGE';

function slugify(str = ''): string {
  return str
    .trim()
    .replace(/[^\u00C0-\u017Fa-zA-Z0-9]+/g, '-')
    .toLowerCase();
}

export function getOfferingImage(offeringImage: VenueFullProfileImageSize | string | undefined): string | undefined {
  // ofrgImage is an object with image variants
  let ofrgImage: string | undefined;
  if (typeof offeringImage === 'object') {
    ofrgImage = offeringImage.original;
  } else {
    ofrgImage = offeringImage;
  }

  // prepend https if not exist since venues endpoint from venue service doesn't return it
  if (typeof ofrgImage === 'string' && !/^https?:\/\//i.test(ofrgImage)) {
    ofrgImage = `https://${ofrgImage}`;
  }
  return ofrgImage;
}

/**
 * This function adds a venue URL to a URL builder
 */
export function addVenueUrlBuilder(
  urlBuilder: UrlBuilder,
  name: string,
  type: string,
  city: string,
  id: string,
  isPreview: boolean = false
): UrlBuilder {
  addPreviewQueryObject(urlBuilder, isPreview);
  urlBuilder.addPath(slugify(city));
  urlBuilder.addPath(slugify(type));
  urlBuilder.addPath(slugify(name));
  urlBuilder.addPath(`${VENUE_PREFIX}${id}`);
  return urlBuilder;
}

/**
 * This function returns the venue profile path based on the venue ID, name, type, and city
 */
export function getVenueProfilePath(
  locale: Locale | undefined,
  name: string,
  type: string,
  city: string,
  id: string
): string {
  const urlBuilder = new UrlBuilder();
  if (locale !== undefined && locale !== 'en-US') {
    urlBuilder.addPath(locale);
  }
  urlBuilder.addPath(slugify(city));
  urlBuilder.addPath(slugify(type));
  urlBuilder.addPath(slugify(name));
  urlBuilder.addPath(`${VENUE_PREFIX}${id}`);
  return `/${urlBuilder.getFullUrl()}`;
}

/*
 * This function adds privew query object to the URL
 */
export function addPreviewQueryObject(urlBuilder, isPreview) {
  if (isPreview) {
    urlBuilder.addQueryParam(queryStringConstants.IS_PREVIEW, 1);
  }
  return urlBuilder;
}

/*
 * This function creates a venue URL based on the venue ID, name, type, city, and locale.
 */
export function createVenueURL(
  id: string,
  name: string,
  type: string,
  city: string,
  locale: Locale,
  cventGuestDomain: string,
  basePath: string,
  searchParams?: SearchParams,
  resultsPageAnalyticsParams?: ResultsPageAnalyticsParams,
  isPreview?: boolean
): LocationObject {
  const urlBuilder = new UrlBuilder();
  urlBuilder.addPath(cventGuestDomain);
  urlBuilder.addPath(basePath.startsWith(URL_SLASH) ? basePath.slice(1) : basePath);
  urlBuilder.addPath(locale);
  addVenueUrlBuilder(urlBuilder, name, type, city, id, isPreview);
  if (searchParams) {
    addExistingParamsToUrl(urlBuilder, searchParams);
  }
  if (resultsPageAnalyticsParams) {
    addResultsPageAnalyticsParamsToUrl(urlBuilder, resultsPageAnalyticsParams);
  }
  return urlBuilder.getLocationObject();
}

/*
 * This function creates a URL for the BRAND PAGE. It takes the listing ID, brand name, and listing code as parameters
 */
export function createBrandPageURLFromListing(
  id: string,
  brandName: string | undefined,
  cventGuestDomain: string,
  listingCode?: string
): LocationObject {
  const sShortStub = id.replace(/-/g, '');
  const urlBuilder = new UrlBuilder();
  urlBuilder.addPath(cventGuestDomain);
  urlBuilder.addPath(queryStringConstants.RFP);
  urlBuilder.addPath(`${slugify(brandName)}-${sShortStub}.aspx`);
  urlBuilder.addQueryParam(queryStringConstants.REFERRAL, listingCode, false, true);
  return urlBuilder.getLocationObject();
}

/**
 * Create a URL to a venue page from a listing code
 */
export function createAdPageURLFromListing(
  id: string,
  name: string,
  type: string,
  city: string,
  locale: Locale,
  cventGuestDomain: string,
  basePath: string,
  listingCode?: string,
  searchParams?: SearchParams,
  resultsPageAnalyticsParams?: ResultsPageAnalyticsParams
): LocationObject {
  const urlBuilder = new UrlBuilder();
  urlBuilder.addPath(cventGuestDomain);
  urlBuilder.addPath(basePath.startsWith('/') ? basePath.substring(1) : basePath);
  urlBuilder.addPath(locale);
  addVenueUrlBuilder(urlBuilder, name, type, city, id);
  urlBuilder.addQueryParam(queryStringConstants.AD_CODE, listingCode, false, true);
  if (searchParams) {
    addExistingParamsToUrl(urlBuilder, searchParams);
  }
  if (resultsPageAnalyticsParams) {
    addResultsPageAnalyticsParamsToUrl(urlBuilder, resultsPageAnalyticsParams);
  }
  return urlBuilder.getLocationObject();
}

/**
 * This code creates a URL with a query string that contains the RFP ID and environment.
 */
export function createAdPageUrlFromPageUrl(
  pageUrl: string,
  cventGuestDomain: string,
  rfpId: string | undefined,
  environment: string = EMPTY_STRING
): LocationObject {
  const urlBuilder = new UrlBuilder();
  urlBuilder.addPath(cventGuestDomain);
  urlBuilder.addPath(pageUrl);
  urlBuilder.addQueryParam(queryStringConstants.RFP_ID, rfpId, false, true);
  if (!isProdEnv(environment)) {
    urlBuilder.addQueryParam(queryStringConstants.ENVIRONMENT, environment, false, true);
  }
  return urlBuilder.getLocationObject();
}

export function getVenueUrl(
  venue: CsnSearchVenue,
  locale: Locale,
  cventGuestDomain: string,
  basePath: string,
  searchParams?: SearchParams,
  resultsPageAnalyticsParams?: ResultsPageAnalyticsParams
): string {
  const isBrandPage = venue.featuredLevel && venue.listingOwnerType === BRAND_PAGE;

  let venueUrl;
  if (isBrandPage && venue.brandName && venue.listingCode) {
    venueUrl = createBrandPageURLFromListing(venue.id, venue.brandName, cventGuestDomain, venue.listingCode);
  } else if (venue.featuredLevel) {
    if (venue.adUrl) {
      venueUrl = createAdPageUrlFromPageUrl(venue.adUrl, cventGuestDomain, searchParams?.rfpId);
    } else {
      venueUrl = createAdPageURLFromListing(
        venue.id,
        venue.name,
        venue.type,
        venue.city,
        locale,
        cventGuestDomain,
        basePath,
        venue.listingCode,
        searchParams,
        resultsPageAnalyticsParams
      );
    }
  } else {
    venueUrl = createVenueURL(
      venue.id,
      venue.name,
      venue.type,
      venue.city,
      locale,
      cventGuestDomain,
      basePath,
      searchParams,
      resultsPageAnalyticsParams
    );
  }

  return new UrlBuilder().mergeLocationObject(venueUrl).getFullUrl();
}

// Returns the venue profile url within SPA
export function prepareVenueProfileUrl(
  venue: CsnSearchVenue,
  locale: Locale,
  cventGuestDomain: string,
  searchParams: ReadonlyURLSearchParams | URLSearchParams | null
): string {
  let venueUrl;
  if (venue.featuredLevel) {
    if (venue.adUrl) {
      const rfpId = searchParams?.get(queryStringConstants.RFP_ID) ?? EMPTY_STRING;
      venueUrl = createAdPageUrlFromPageUrl(venue.adUrl, cventGuestDomain, rfpId);
      venueUrl = new UrlBuilder().mergeLocationObject(venueUrl).getFullUrl();
    } else {
      const venueProfilePath = getVenueProfilePath(locale, venue.name, venue.type, venue.city, venue.id);
      const additionalParams: Params[] = venue.listingCode
        ? [{ key: queryStringConstants.AD_CODE, value: venue.listingCode }]
        : [];
      venueUrl = prepareUrl(venueProfilePath, searchParams, ...additionalParams);
    }
  } else {
    const venueProfilePath = getVenueProfilePath(locale, venue.name, venue.type, venue.city, venue.id);
    venueUrl = prepareUrl(venueProfilePath, searchParams);
  }

  return venueUrl;
}

// Determines whether to open the venue profile in new tab or not
// Venue Profile is opened in a new tab if the venue is a microsite or a brand page
export function shouldNavigateToNewTab(
  e: React.MouseEvent<HTMLAnchorElement>,
  venueListingOwnerType: string | undefined,
  venueFeaturedLevel: string | undefined
): boolean {
  const isMicrosite = venueListingOwnerType === MICROSITE;
  const isBrandPage: boolean = venueFeaturedLevel !== undefined && venueListingOwnerType === BRAND_PAGE;
  return e.metaKey || isMicrosite || isBrandPage;
}

export function getVenueProfileUrlForShoppingCart(
  venue: ShoppingCartItem,
  locale: Locale,
  searchParams: ReadonlyURLSearchParams | URLSearchParams | null
): string {
  const venueProfilePath = getVenueProfilePath(
    locale,
    venue.name ?? '',
    venue.type ?? '',
    venue.city ?? '',
    venue.venueId
  );
  return prepareUrl(venueProfilePath, searchParams);
}

/**
 * This function checks if the current venue from the shopping cart
 * is the same as the venue on the venue profile page. This helps
 * to avoid displaying an unnecessary spinner when the planner
 * is already viewing the selected venue.
 */
export function isVenueProfileSelected(venue: ShoppingCartItem, locale: Locale, existingPath: string): boolean {
  const venueProfilePath = getVenueProfilePath(
    locale,
    venue.name ?? '',
    venue.type ?? '',
    venue.city ?? '',
    venue.venueId
  );

  return venueProfilePath === existingPath;
}
